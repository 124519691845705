import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const PGD = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      seo={{
        title: "Grupa PGD",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #1C085F, #2E1485, #3C1CA6, #2E1485, #1C085F )",
        icons: "#4a059b",
        navClass: "pgd",
        ogImage: require("../../../assets/img/portfolio/pgd_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/grupa-pgd/",
      }}
    >
      <PortfolioHeader name="pgd" height="132" />
      <section className="container-fluid pgd_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>PGD Group</h1>
              <ul>
                <li>Website</li>
                <li>Competition entry</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                The PGD Group is the first Polish dealership group; multi-brand
                precursor in Poland and the first Polish dealer who created a
                (multi-brand) car dealership abroad. The PGD Group is the first
                dealer in Poland to cross the limit of 10,000 cars sold in a
                year and 200,000 cars sold in history. Since 2004, the company
                has been listed on the lists of 500 largest Polish companies
                prepared by “Polityka” and “Rzeczpospolita”
              </p>
              <p>Goal:</p>
              <ol>
                <li>Design a new website supporting sales </li>
                <li>
                  To present the brand as a modern enterprise
                  <br /> corresponding to its role as market leader.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid pgd_section_3">
        <div className="row row_1">
          <div className="col-md-6 offset-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/pgd_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row_2">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/pgd_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_3">
          <div className="col-md-8 offset-md-4 text-right">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/pgd_main_3.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid pgd_section_4">
        <div className="row">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/pgd_main_4.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Result of our work:</h3>
              <p>
                We have designed a modern and outstanding website. We have
                developed a concept that emphasized the leading role of the PGD
                Group on the Polish market, and, finally, the customer has not
                decided to change his website.
              </p>
            </div>
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default PGD;
